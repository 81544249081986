.profile{
    margin-top: 100px;
    overflow: hidden;
}
.profile .profile-details .logout{
    background: none;
    color: #848484;
    height: 50px;
    font-weight: 500;
    /* text-align: left; */
}
.profile .profile-details .logout:hover{
    color: #000;
    background-color: #dedede;
}

.profile .welcome-title{
    font-size: 24px;
    font-weight: 500;
    text-transform: capitalize;
    margin-bottom: 16px;
}
.profile .profile-details{
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: #F8F8F8;
    color: #000;
    font-size: 18px;
    min-height: 90vh;
    height: 100%;
    height: max-content
}
.en .profile .profile-details{
    padding-left: 75px;
    padding-right: 16px;
}
.ar .profile .profile-details{
    padding-right: 75px;
    padding-left: 16px;
}
.profile .details{
    padding-top: 40px;
}
.profile .tab-title{
    font-size: 24px;
    margin-bottom: 16px;
    font-weight: 500;
}
.profile .profile-links{
    margin-top: 24px;
}
.profile .profile-links p.active{
    color: var(--primary-color);
}
.profile .profile-links p.active svg{
    fill-opacity: .3;
    fill: red;

}

.profile .profile-links p{
    height: 56px;
    margin-bottom: 8px;
    font-weight: 500;
    font-size: 18px;
    cursor: pointer;
    display: flex;
    gap: 8px;
    align-items: center;
}
.profile .tabs{
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 3rem;
}
.profile .tabs .tab{
    background-color: #dedede;
    color: #000;
    padding: 12px 16px;
    width: 100%;
    border-radius: 8px;
    text-align: center;
    cursor: pointer;
    margin: 0;
}
.profile .tabs .tab:hover{
    background-color: #8b8b8b;
    color: #fff;
}
.profile .tabs .tab.active{
    background-color: var(--primary-color);
    color: #fff;
}
.profile .profile-pic .pic{
    position: relative;
}
.profile .profile-pic img{
    width: 110px;
    height: 110px;
}

.image-placeholder {
    position: relative;
    max-width: 110px;
}
.image-placeholder .avatar-edit {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0; 
    z-index: 1;
}
.image-placeholder .avatar-edit input {
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
}
.image-placeholder .avatar-edit input + label {
          display: inline-block;
          width: 34px;
          height: 34px;
          margin-bottom: 0;
          border-radius: 100%;
          background: #FFFFFF;
          border: 1px solid transparent;
          box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
          cursor: pointer;
          font-weight: normal;
          transition: all .2s ease-in-out; 
}
.image-placeholder .avatar-edit input + label:hover {
            background: #f1f1f1;
            border-color: #d6d6d6; 
}
.image-placeholder .avatar-edit input + label:after {
            content: "\F4C9";
            /* font-family: 'FontAwesome'; */
            color: #757575;
            position: absolute;
            top: 6px;
            left: 0;
            right: 0;
            text-align: center;
            margin: auto; 
}
.image-placeholder .avatar-preview {
      width: 110px;
      height: 110px;
      position: relative;
      border-radius: 30px;
}
.image-placeholder .avatar-preview > div {
        width: 100%;
        height: 100%;
        border-radius: 30px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        overflow: hidden; 
}
.image-placeholder .avatar-preview > div img {
          height: 100%;
          width: 100%;
}
@media only screen and (max-width: 500px) {
    .profile .profile-details {
        min-height: auto;
    }
    .profile,
    .en .profile .profile-details {
        padding-left: 16px;
        padding-right: 16px;
    }
}