.product{
    margin-top: 140px;
    padding: 0 75px;
    min-height: 100vh;
}
.product  .prices p{
    margin: 0;
}
.product  .prices{
    width: 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
    height: 35.5px;
}
.product  .price{
    font-size: 18px;
    color: var(--primary-color);
    font-weight: 500;
}
.product  .discount{
    font-size: 16px;
    background-color: #FEF1E6;
    color: #055902;
    line-height: 19.23px;
    padding: 8px 12px;
    font-weight: 500;
    border-radius: 60px;
}
.product  .brand{
    margin-bottom: 16px;
}
.product .subCategory{
    color: var(--primary-color);
    padding: 4px 8px;
    border: 1px solid var(--primary-color);
    border-radius: 4px;
    margin-bottom: 1.5rem;
    display: block;
    width: fit-content;
}
.product .description {
    margin-bottom: 22px;
}
.product  .brand p,
.product  .description p{
    color: #000;
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 12px;
    display: block;
}
.product  .brand span{
    color: #000;
}
.product  .description span{
    font-size: 12px;
    font-weight: 400;
    line-height: 19.61px;
    color: #848484;
}
.product .main-img{
    min-width: 490px!important;
    width: 100%!important;
    min-height: 400px!important;
    max-height: 600px!important;
    height: 100%!important;
}
.product .swiper-slide img{
    width: 90px!important;
    height: 90px!important;
}
.product .swiper-pagination{
    position: relative;
    margin-top: 1.5rem;
}
.product .product-name{
    font-size: 24px;
    font-weight: 700;
    line-height: 28.13px;
    color: #000000;
    margin-bottom: 16px;
}
.product .amounts{
    margin-bottom: 32px;
}
.product .amounts button{
    font-size: 22px;
    background: var(--primary-color);
    height: 40px;
    width: 40px;
    color: #fff;
    border: none;
}

.product .category{
    font-size: 18px;
    background-color: var(--primary-color);
    width: fit-content;
    color: #fff;
    border-radius: 5px;
    padding: 4px 8px;
}
.product .variant-values{
    gap: 20px 50px;
    display: grid;
    grid-template-columns: auto auto auto;
}
.product .addToCart{
    background-color: var(--primary-color);
    color: #fff;
    text-decoration: none;
    width: 100%;
    font-size: 20px;
    padding: 12px 50px;
    border: 1px solid var(--primary-color);
}
.product .addToCart:hover{
    background: none;
    color: var(--primary-color);
}
.product .buy{
    background: var(--primary-color);
    height: 56px;
    width: 555px;
    border-radius: 10px;
}
.product .amounts span{
    font-size: 22px;
}
.product .old-price{
    text-decoration: line-through;
}
.similar_products{
    background-color: #F8F8F8;
    padding: 40px 75px;
    margin-top: 180px;
}
/* .similar_products h1{
    font-size: 32px;
    font-weight: 700;
    line-height: 37.5px;
    margin-bottom: 32px;
} */
@media only screen and (max-width: 850px) {
    .product .main-img{
        width: 280px!important;
        height: 280px!important;
    }
}
@media only screen and (max-width: 500px) {
    .product {
        margin-top: 8rem;
        margin-bottom: 4rem;
        padding: 0 20px;
        min-height: 80vh;
    }    
    .product  .prices{
        width: 100%;
    }
    .product .main-img{
        min-width: 150px!important;
        width: 150px!important;
        min-height: 150px!important;
        height: 150px!important;
        margin-bottom: 1rem;
    }
    .product .swiper-slide img{
        width: 60px!important;
        height: 60px!important;
    }
    .product .col-md-5{
        margin-bottom: 2rem;
    }
    .similar_products{
        margin: 0;
        padding: 20px;
    }
    .similar_products h1{
        font-size: 22px;
        margin-bottom: 1.5rem;
    }
}