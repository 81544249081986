.brands-section{
  padding: 0 75px;
  margin-top: 160px;
}
.brands-section .type-title{
  text-align: center;
  margin-bottom: 40px;
}
.brands-section .type-title span{
  font-size: 72px;
  font-weight: 500;
  line-height: 86.54px;
  display: block;
  color: #000;
}
.brands-section .brands{
  padding: 0;
}
.brands-section .brand{
  margin-bottom: 40px;
}
.brands-section .brand-card{
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.brands-section .brand-card .brand-image{
  border-radius: 10px;
  margin-bottom: 20px;
  height: 100%;
}
.brands-section .brand-card p{
  font-size: 18px;
  font-weight: 500;
  line-height: 21.64px;
  margin-bottom: 16px;
}
.brands-section .brand-card .more-details{
  background: none;
  border: 1px solid var(--primary-color);
  border-radius: 10px;
  font-size: 14px;
  height: 48px;
  color: var(--primary-color);
}
@media only screen and (max-width: 500px) { 
  .brands-section {
    padding: 0 15px;
    margin-top: 100px;
  }
  .brands-section .type-title span {
    font-size: 40px;
    line-height: 1;
  }
  .brands-section .brand{
    margin-bottom: 20px;
  }
  brands-section .brand-card .more-details {
    height: 40px;
  }
}