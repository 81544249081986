.footer{
    background-color: var(--footer-bg);
    color: #fff;
    padding: 24px 88px 0;
    margin-top: 66px;
}
.default-section{
    border-bottom: 1px solid #D0D0D0;
}
.footer .contacts-title{
    font-size: 18px;
    font-weight: 400;
    line-height: 21.64px;
}
.footer .contacts-title{
    font-size: 18px;
    font-weight: 400;
    line-height: 21.64px;
    margin-bottom: 24px;
}
.footer .contacts p{
    font-size: 14px;
    font-weight: 400;
    line-height: 16.83px;
    margin-bottom: 22.5px;
    display: flex;
    gap: 3.5px;
    align-items: center;
}
.footer .description{
    width: 75%;
    margin: auto;
}
.footer .description img{
    margin-bottom: 12px;
}
.footer .description p{
    font-size: 10px;
    line-height: 15px;
    font-weight: 400;
}
.footer .follow{
    font-size: 18px;
    line-height: 21.64px;
    margin-bottom: 24px;
}
.footer .icons{
    display: flex;
    gap: 16px;
}
.footer .links a{
    display: block;
    margin-bottom: 24px;
    color: #fff;
    font-size: 18px;
    line-height: 21.64px;
    text-decoration: none;
}
.footer .contacts a{
    color: #fff;
    font-size: 14px;
    text-decoration: none;
}
.footer .links .address{
    margin-top: 32px;
    font-size: 12px;
    line-height: 18px;
    display: flex;
    gap: 10px;
}
.footer .links .address span{
    display: block;
    width: fit-content;
}
.footer .power{
    font-size: 14px;
    line-height: 21px;
    margin-top: 6px;
    padding-bottom: 8px;
}
.footer .power a{
    font-size: 16px;
    line-height: 19.23px;

}
.footer .one{ order: 1;}
.footer .two{ order: 2;}
.footer .three{ order: 3;}
@media only screen and (max-width: 500px) {
    .footer {
        padding: 24px 20px 0;
        margin-top: 30px;
    }
    .footer .description {
        width: 95%;
    }
    .footer .one{ order: 1;}
    .footer .two{ order: 3;}
    .footer .three{ order: 2; margin-top: 1rem;}
}