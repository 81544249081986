.cart{
    margin-top: 9rem;
}

.cart .prod-btn{
    background: none;
    border: none;
    color: #fff;
    background-color: var(--primary-color);
    padding: 0px 9px;
    border-radius: 5px;
    width: 32px;
    height: 32px;
}
.product-name{
    font-size: 16px;
    font-weight: 500;
}
.product-amount{
    font-size: 16px;
    color: #848484;
}
.cart .trash{
    background: none;
    padding: 0;
    color: var(--bs-danger);
}
.cart .minus{
    padding: 0px 11px;
}
.cart .product-cart{
    margin-bottom: 1.5rem;
    margin-top: 1rem;
    border-bottom: 1px solid #dedede;
    padding-bottom: 1.5rem;
}
.cart .payment-details{
    background-color: var(--bg-primary-color);
    color: #848484;
}
.cart .continue{
    background: var(--primary-color);
    color: #fff;
    margin-top: 2rem;
    border-radius: 8px;
    font-weight: 500;
}
.cart .product-cart:last-child{
    margin-bottom: 0;
    border-bottom: none;
    padding-bottom: 1rem;
}
.cart .coupon-code {
    background: var(--primary-color);
    padding: 20px 15px;
    border-radius: 10px;
    margin-bottom: 20px;
}
.cart .coupon-code h5 {
    color: #fff;
    margin-bottom: 15px;
}
.cart .form-coupon .form-group {
    position: relative;
}
.cart .form-coupon .form-control {
    background: #fff;
    border: 0;
    border-radius: 35px;
    height: 50px;
    color: #505153;
}
.cart .form-coupon .btn-site {
    height: 40px;
    position: absolute;
    top: 5px;
    border-radius: 35px;
    background-color: var(--primary-color);
}
.cart .form-coupon .btn-danger {
    height: 40px;
    position: absolute;
    top: 5px;
    border-radius: 35px;
    color: #fff;
    background-color: var(--bs-btn-bg);
    padding: 10px 15px;
}
.ar .cart .form-coupon .btn-site,
.ar .cart .form-coupon .btn-danger {
    left: 4px;
}
.en .cart .form-coupon .btn-site,
.en .cart .form-coupon .btn-danger {
    right: 4px;
}

@media only screen and (max-width: 500px) {
    .cart .row div:first-of-type img{
        width: 60px;
        height: 60px;
    }
    .cart .row div:nth-of-type(1){
        gap: 8px;
        /* align-items: center; */
        margin-bottom: 4px;
    }
    .cart .row div:nth-of-type(1) h4{
        font-size: 18px;
    }
    .cart .row div:nth-of-type(1) h5{
        font-size: 16px;
    }
    .cart .trash img{
        width: 18px!important;
        height: 18px!important;
    }
}