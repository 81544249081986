.categories-home{
  margin-top: 4rem;
  margin-bottom: 8rem;
  padding: 0 16px;
}
.categories-home .section-categories{
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto auto;
  gap: 8px;
}
.categories-home .section-title{
  text-align: center;
  margin-bottom: 2rem;
  font-size: 48px;
  font-weight: 500;
}
.categories-home .category .information{
  display: block;
}
/* .categories-home .category .showMore{
  display: none;
}
.categories-home .category .showMore span{
  background-color: #F27405;
  padding: 20px 60px;
  border-radius: 8px;
} */
.categories-home .category{
  /* background: #0000004D; */
  position: relative;
  height: auto;
  text-decoration: none;
  /* overflow: hidden;
  transition: hover 1s ease-in-out; */
}
/* .categories-home .category:hover .information{
  display: none;
} */
/* .categories-home .category:hover .showMore{
  display: block;
} */
.categories-home img{
  height: 160px;
}
/* .categories-home .information,
.categories-home .category .showMore{
  position: absolute;
  top: 50%;
  left: 50%;
  width: 75%;
  transform: translate(-50%, -50%);
  color: #FFFFFF;
  text-align: center;
} */
.categories-home .title{
  font-size: 16px;
  margin-bottom: 0;
  margin-top: 12px;
  font-weight: 500;
  color: #000;
  text-decoration: none;
  text-align: center;
}
/* .categories-home .number{
  font-size: 24px;
  font-weight: 400;
  margin: 0;
  line-height: 27.91px;
} */
@media only screen and (max-width: 850px) {
  .categories-home img{
    height: 84px;
  }
  .categories-home .title{
    font-size: 14px;
  }
}
@media only screen and (max-width: 500px) {
  .categories-home{
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .categories-home .section-title{
    font-size: 30px;
    margin-bottom: 1rem;
  }
  .categories-home .section-categories{
    grid-template-columns: auto auto auto;
    gap: 12px;
  }
  .categories-home .category {
    margin-bottom: 1rem;
  }
  /* .categories-home .category .showMore span {
    background-color: #F27405;
    padding: 12px 25px;
  } */
}