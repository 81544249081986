.navbar{
    background: initial;
    position: absolute;
    padding: 5px 75px;
    top: 0;
    width: 100%;
    z-index: 2;
    border-bottom: 0.05px solid #D0D0D0;
} 
.navbar .one{ order: 1;} 
.navbar .two{ order: 2;} 
.navbar .three{ order: 3;} 
.navbar .navbar-nav{
    gap: 16px;
}
.navbar a,
.navbar .nav-link{
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    padding: 12px 16px;
    line-height: normal
} 
.navbar a:hover{
    color: rgba(255, 254, 254, 0.651)
}
.navbar a.active{
    color: var(--primary-color) !important;
} 
.navbar-collapse {
    justify-content: flex-end;
}
.navbar .cart-num{
    background-color: #b96464;
    color: #fff;
    position: absolute;
    border-radius: 50%;
    right: -12px;
    top: -12px;
    padding: 0 4px;
    font-size: 14px;
}

@media only screen and (max-width: 500px) {
    .navbar {
        padding: .5rem 0;
    }
    .navbar .one{ order: 1;} 
    .navbar .two{ order: 3;} 
    .navbar .three{ order: 2;} 
    .navbar-collapse {
        position: absolute;
        background: #fff;
        top: 66px;
        z-index: 99999999999;
        padding-top: 16px;
        left: 0;
        width: 100% !important;
        border-bottom: 1px solid #dedede;
    }
    .navbar .nav-link {
        color: #222!important;
    }
    .navbar .navbar-nav {
        gap: 0;
    }
    .navbar-collapse a {
        margin-bottom: 0;
    }
    .navbar .logo img{
        width: 50px;
        height: 50px;
    }
}