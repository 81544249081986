@font-face {
  font-family: 'AktivGrotesk';
  src: url('./common/Fonts/AktivGrotesk/AktivGrotesk-Regular.otf') format('otf'),
       url('./common/Fonts/AktivGrotesk/AktivGrotesk-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'AktivGrotesk';
  src: url('./common/Fonts/AktivGrotesk/AktivGrotesk-Thin.otf') format('otf'),
       url('./common/Fonts/AktivGrotesk/AktivGrotesk-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'AktivGrotesk';
  src: url('./common/Fonts/AktivGrotesk/AktivGrotesk-ThinItalic.otf') format('otf'),
       url('./common/Fonts/AktivGrotesk/AktivGrotesk-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'AktivGrotesk';
  src: url('./common/Fonts/AktivGrotesk/AktivGrotesk-Medium.otf') format('otf'),
       url('./common/Fonts/AktivGrotesk/AktivGrotesk-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'AktivGrotesk';
  src: url('./common/Fonts/AktivGrotesk/AktivGrotesk-MediumItalic.otf') format('otf'),
       url('./common/Fonts/AktivGrotesk/AktivGrotesk-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'AktivGrotesk';
  src: url('./common/Fonts/AktivGrotesk/AktivGrotesk-Bold.otf') format('otf'),
       url('./common/Fonts/AktivGrotesk/AktivGrotesk-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'AktivGrotesk';
  src: url('./common/Fonts/AktivGrotesk/AktivGrotesk-BoldItalic.otf') format('otf'),
       url('./common/Fonts/AktivGrotesk/AktivGrotesk-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'AktivGrotesk';
  src: url('./common/Fonts/AktivGrotesk/AktivGrotesk-Italic.otf') format('otf'),
       url('./common/Fonts/AktivGrotesk/AktivGrotesk-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Almarai-Bold';
  src: url('./common/Fonts/Almarai-Bold.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}
.en{
  font-family: 'AktivGrotesk', Arial, sans-serif;;
  direction: ltr;
}
.ar{
  direction: rtl;
  /* font-family: "Cairo", sans-serif; */
  font-family: 'Almarai-Bold', sans-serif;
  font-optical-sizing: auto;
}
.custom-div {
  background-color: var(--primary-color);
  padding: 5rem 2rem;
  color: #ffffff;
}

.custom-div h1 {
  margin-bottom: 0;
  font-weight: 600;
}

button {
  background-color: black;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
}

/* Header Styles */
header {
  background-color: white;
  padding: 1rem 2rem;
}

header nav ul {
  list-style: none;
  display: flex;
  justify-content: space-around;
  margin: 0;
  padding: 0;
}

header nav ul li {
  margin: 0 1rem;
  color: black;
  cursor: pointer;
}

/* Featured Products Styles */
.featured-products {
  background-color: #2E7A9D;
  padding: 2rem;
}

.featured-products h2 {
  margin-bottom: 1rem;
}

.featured-products .products {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.featured-products .products .product {
  background-color: white;
  color: black;
  padding: 1rem;
  border-radius: 10px;
  text-align: center;
}

/* Best Selling Products Styles */
.best-selling-products {
  background-color: #3E9ABD;
  padding: 2rem;
}

.best-selling-products h2 {
  margin-bottom: 1rem;
}

.best-selling-products .products {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.best-selling-products .products .product {
  background-color: white;
  color: black;
  padding: 1rem;
  border-radius: 10px;
  text-align: center;
}

/* Download Section Styles */
.download-section {
  background-color: #4EBADC;
  padding: 2rem;
}

.download-section h2 {
  margin-bottom: 1rem;
}

.download-section button {
  margin-top: 10px;
}

/* Footer Styles */
footer {
  background-color: #0E3A5D;
  padding: 2rem;
}

.footer-content {
  display: flex;
  justify-content: space-between;
}

.footer-content .quick-links ul {
  list-style: none;
  padding: 0;
}

.footer-content .quick-links ul li {
  margin: 0.5rem 0;
}

.footer-content .social-media,
.footer-content .qr-code {
  display: flex;
  flex-direction: column;
  align-items: center;
}

footer p {
  margin-top: 2rem;
  font-size: 0.8rem;
}
.btn-primary{
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}
.btn-primary:hover{
  opacity: .8;
  background-color: var(--primary-color);
}
.paths{
  padding: 20px 0;
  margin-bottom: 30px;
}
.paths a{
  background: none !important;
  color: #333 !important;
  padding: 0 !important;
  text-decoration: none
}
.paths .gt{
  margin: 0 6px;
}
.paths h1{
  font-size: 24px;
}
.header-home .carousel-indicators button{
  width: 14px;
  height: 14px;
  background-color: #fff !important;
  border-radius: 50%;
}
.header-home .carousel-indicators button.active{
  background-color: #888;
}
.header-home .carousel-control-next, 
.header-home .carousel-control-prev{
  display: none;
}

.scroll-animation div p{
  width: 100%;
  overflow: hidden;
}
.scroll-animation:hover div p{
  animation: scroll 5s infinite;
  animation-timing-function: ease-in-out;
}
@keyframes scroll {
  0% {
    translate: 0;
    overflow: visible;
  }
  100% {
    translate: -100%;
    overflow: visible;
  }
}
.error-page{
  margin-top: 140px;
  margin-bottom: 100px;
}
.error-page h1{
  font-size: 72px;
  font-weight: 500;
  margin-bottom: 24px;
}
.error-page h4{
  font-size: 48px;
  font-weight: 400;
  margin-bottom: 24px;
}
.error-page .btn{
  font-size: 14px;
  width: 400px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}
.error-page .btn:hover{
  border-color: var(--primary-color);
}
.no-data{
  text-align: center;
}
.no-data p{
  font-weight: 600;
  font-size: 22px;
}
.section-title{
  text-align: center;
  margin-bottom: 16px;
  position: relative;
}
.section-title::before,
.section-title::after{
  content: '';
  position: absolute;
  height: 3px;
  width: 100px;
  border-radius: 8px;
  background-color: #dedede;
  top: 47%;
}
.section-title::before{
  left: 33%;
}
.section-title::after{
  right: 33%;
}
@media only screen and (max-width: 500px) {
  .section-title::before,
  .section-title::after{
    width: 60px;
  }
  .section-title::after {
    right: 8%;
  }
  .section-title::before {
    left: 8%;
  }
}


.accept-modal .modal-body{
  overflow-x: hidden;
}
.accept-modal .btn:disabled{
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}
.accept-modal .modal-body{
  overflow: scroll;
  height: 77vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.accept-modal .rule label{
  font-size: 20px;
  font-weight: 600;
}

.accept-modal .modal-content{
  height: 90vh
}