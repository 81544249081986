*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
:root{
  --primary-color: #F27405;
  --bg-primary-color: #FEF1E6;
  --footer-bg: #343434
}
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.text-primary{
  color: var(--primary-color) !important;
}

.loader {
    width: 50px;
    margin: auto;
    aspect-ratio: 1;
    display: grid;
    border: 4px solid #0000;
    border-radius: 50%;
    border-right-color: var(--primary-color);
    animation: l15 1s infinite linear;
  }
  .loader::before,
  .loader::after {    
    content: "";
    grid-area: 1/1;
    margin: 2px;
    border: inherit;
    border-radius: 50%;
    animation: l15 2s infinite;
  }
  .loader::after {
    margin: 8px;
    animation-duration: 3s;
  }
  @keyframes l15{ 
    100%{transform: rotate(1turn)}
  }