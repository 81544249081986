.branches-section{
  padding: 0 75px;
  margin-top: 160px;
}
.branches-section .type-title{
  text-align: center;
  margin-bottom: 40px;
}
.branches-section .type-title span{
  font-size: 72px;
  font-weight: 500;
  line-height: 86.54px;
  display: block;
  color: #000;
}
.branches-section .branche{
  margin-bottom: 40px;
}
.branches img{
  margin-bottom: 20px;
  border-radius: 10px;
}
.branches .branche{
  margin-bottom: 40px;
}
.branches .details{
  text-align: center;
}
.branches .details p{
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  margin-bottom: 8px;
}
.branches .details .title{
  font-weight: 500;
}
.branches .details .location{
  color: var(--primary-color);
  text-decoration: none;
  padding: 15px 80px;
  border-radius: 10px;
  border: 1px solid var(--primary-color);
  display: block;
  margin: auto;
  margin-top: 16px;
  width: max-content;
  font-weight: 400;
}
@media (max-width: 500px){
  .branches-section {
    padding: 0 15px;
    margin-top: 100px;
  }
  .branches-section .type-title span {
    font-size: 40px;
    line-height: 1;
  }
  .branches .details .location {
    padding: 10px 25px;
  }
}