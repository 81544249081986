.header-home .carousel-item img{
    height: 100vh;
}
.header-home .carousel-indicators {
    position: absolute;
    right: 75px;
    top: 50%;
    margin: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    padding: 0;
    flex-direction: column;
    bottom: auto;
    left: auto;
    gap: 12px;
    transform: translate(0, -50%);
}
.carousel-indicators [data-bs-target]{
    border-top: none;
    border-bottom: none;
}
.header-home .social-media{
    position: absolute;
    z-index: 6;
    top: 50%;
    left: 0;
    background: #0000004D;
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 95px;
    text-align: center;
    padding: 34px 0;
    border-radius: 0px 10px 10px 0px;
    transform: translate(0, -50%);
}
.header-home .social-media img{
    width: 40px;
    height: 40px;
    background: #000;
    border-radius: 50%;
    padding: 8px;
}
.header-home .title{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    text-align: center;
}
.header-home .title span{
    font-size: 48px;
    line-height: 57.7px;
    font-weight: 400;
    margin-bottom: 24px;
    display: block;
}
.header-home .title p{
    font-size: 88px;
    line-height: 105.78px;
    font-weight: 400;
    text-wrap: nowrap;
    margin-bottom: 0;
}
.header-home .carousel-inner::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #0000004D;
    z-index: 1;
}
@media only screen and (max-width: 500px) {
    .header-home .carousel-item img{
        height: 50vh;
    }
    .header-home .carousel-indicators{
        right: 16px;
        z-index: 1;
    }
    .header-home .social-media svg ,
    .header-home .social-media img {
        width: 35px;
        height: 35px;
    }
    .header-home .social-media {
        z-index: 1;
        top: 29%;
        left: 0;
        gap: 12px;
        width: 52px;
        padding: 16px 0;
    }
}