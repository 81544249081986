.showrooms-section{
  position: relative;
}
.showrooms {
    text-align: center;
    margin-top: 2px !important;
    position: relative;
    min-height: 100vh;
    overflow: hidden;
    padding: 69px 75px;
    background-repeat: round !important;
}
.showrooms .gif{
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    height: 100%;
    width: 100%;
}

.showrooms h1{
  margin-bottom: 40px;
  color: #000;
  font-size: 48px;
  line-height: 57.7px;
  font-weight: 400;
}
.showrooms img{
  margin-bottom: 20px;
  border-radius: 10px;
}
.showrooms .rooms .cate{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.showrooms .details{
  text-align: center;
}
.showrooms .details p{
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  margin-bottom: 8px;
}
.showrooms .details .location{
  background-color: var(--primary-color);
  text-decoration: none;
  padding: 8px 60px;
  border-radius: 10px;
  border: 1px solid var(--primary-color);
  color: #fff;
  display: block;
  margin: auto;
  margin-top: 16px;
  margin-bottom: 16px;
  width: max-content;
  font-weight: 400;
}
@media only screen and (max-width: 500px) {
  .showrooms{
    padding: 32px 22px;
    min-height: auto;

  }
  .showrooms h1 {
    margin-bottom: 20px;
    font-size: 32px;
    line-height: 1;
  }
  .showrooms .details .location {
    padding: 9px 10px;
  }
  .mx-3.card{
    margin: 0 0 1rem 0 !important;
    border-radius: 18px !important;
  }
}