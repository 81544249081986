.aboutUs{
  margin-top: 160px;
  margin-bottom: 40px;
}
.aboutUs .about-us{
  display: flex;
  margin-bottom: 3rem;
}
.aboutUs .about-us .description{
  background: #F8F8F8;
  padding: 73px 75px;

}
.aboutUs .about-us .image{
  
}
.aboutUs .type-title{
  text-align: center;
  margin-bottom: 40px;
}
.aboutUs .type-title span{
  font-size: 72px;
  font-weight: 500;
  line-height: 86.54px;
  display: block;
  color: #000;
}
@media only screen and (max-width: 500px) {
  .aboutUs {
    padding: 1rem;
    margin-top: 5rem;
    margin-bottom: 0;
  }
  .aboutUs .type-title span {
    font-size: 40px;
    line-height: 1;
  }
  .aboutUs .about-us{
    flex-direction: column;
  }
  .aboutUs .about-us .description {
    background: #F8F8F8;
    padding: 40px 20px;
    width: 100% !important;
  }
  .aboutUs .about-us .image{
    width: 100%!important;
    margin-top: 1rem;
  }
}