.products-section {
  text-align: center;
  position: relative;
  background-color: #F8F8F8;
  padding-top: 72px;
}
.products-section .tabs{
  margin-bottom: 56px;
}
.products-section .tabs .swiper-pagination{
  display: none;
}
.products-section .tabs .swiper-wrapper{
  width: fit-content;
  margin: auto;
}
.products-section .tab{
  font-weight: 500;
  padding: 6px;
  /* border-radius: 10px; */
  color: #000;
  cursor: pointer;
  text-wrap: nowrap;
}
.products-section .tab.active{
  border-bottom: 1px solid var(--primary-color);
  color: var(--primary-color);
}
.products-section h1{
  font-size: 48px;
  margin-bottom: 40px;
  line-height: 57.7px;
  font-weight: 500;
}
.products-section .product-card {
  cursor: pointer;
}
.products-section .product-card img {
  margin-bottom: 16px;
  border-radius: 10px 10px 0 0;
  height: 250px;
}
.products-section .product-card .title {
  font-size: 18px;
  line-height: 21.64px;
  font-weight: 500;
  margin-bottom: 20px;
  height: 2.5rem;
}
.products-section .product-card .price {
  font-size: 18px;
  line-height: 21.64px;
  font-weight: 500;
  margin-bottom: 24px;
  color: var(--primary-color);
}
.products-section .mySwiper{
  padding: 0 5px;
}
.products-section .swiper-pagination{
  margin-top: 36px;
  margin-bottom: 42px;
  position: relative;
}
.products-section .swiper-wrapper{
  height: auto;
}
.products-section .swiper-pagination-bullet{
  height: 14px;
  width: 14px;
  background: none;
  border: 1px solid var(--primary-color);
}
.products-section .swiper-pagination-bullet.swiper-pagination-bullet-active{
  background-color: var(--primary-color);
}
@media only screen and (max-width: 500px) {
  .products-section h1 {
    padding-top: 35px;
    margin-bottom: 40px;
    font-size: 32px;
    line-height: 1;
  }
  .products-section .tab {
    padding: 8px 12px;
    height: auto;
  }
}